<template>
  <div class="card">
    <div class="card-body px-lg-5 py-lg-5">
      <h5 class="card-title text-center">Reset Password</h5>
      <div v-if="expired" class="mt-3">
        <p class="text-center">
          <i class="fa fa-exclamation-triangle fa-3x text-warning"></i>
        </p>
        <p class="lead">
          Your email to reset your password has expired or invalid.
        </p>
        <p class="text-center">
          <router-link to="/"
            ><base-button type="primary" class="ntt mr-2" outline
              >Home</base-button
            ></router-link
          >
          <router-link to="/login"
            ><base-button type="info" class="ntt" outline
              >Log In</base-button
            ></router-link
          >
        </p>
      </div>
      <validation-observer v-else ref="observer">
        <form role="form" @submit.prevent="resetPassword()">
          <base-alert v-if="error" type="danger"
            ><strong
              >Could not reset password. Something went wrong.</strong
            ></base-alert
          >
          <div>
            <div class="form-group">
              <validation-provider
                name="Password"
                rules="required|min:6"
                v-slot="{ errors }"
              >
                <base-input
                  name="password"
                  v-model="reset.password"
                  type="password"
                  placeholder="New Password"
                  :error="errors[0]"
                ></base-input>
              </validation-provider>
            </div>
            <div class="form-group">
              <validation-provider
                name="ConfirmPassword"
                rules="required|min:6|password:@Password"
                v-slot="{ errors }"
              >
                <base-input
                  v-model="reset.confirm_password"
                  type="password"
                  placeholder="Confirm Password"
                  :error="errors[0]"
                ></base-input>
              </validation-provider>
            </div>
            <div class="text-center">
              <base-button
                @click="resetPassword()"
                :disabled="loadings.button"
                type="primary"
                class="my-4 ntt"
              >
                <spinner
                  v-if="loadings.button"
                  type="grow"
                  class="spinner-grow-sm"
                ></spinner>
                <span v-else>Save</span></base-button
              >
            </div>
          </div>
          <input type="submit" style="display:none;" />
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    Spinner,
    ValidationProvider,
    ValidationObserver
  },
  data: function() {
    return {
      reset: {
        password: "",
        confirm_password: ""
      },
      loadings: {
        button: false
      },
      expired: false,
      error: false
    };
  },
  mounted() {
    this.validateToken();
  },
  methods: {
    validateToken() {
      this.$axios
        .get(`/reset-password/${this.$route.params.token}/`)
        .catch(err => {
          if (err.response) {
            this.expired = true;
          }
        });
    },
    resetPassword() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.error = false;
        this.$axios
          .post(`/reset-password/${this.$route.params.token}/`, this.reset)
          .then(res => {
            this.$store.dispatch("login", {
              token: res.data,
              remember: true
            });
            this.$router.push(this.$store.getters.after_login);
          })
          .catch(err => {
            if (err.response) {
              this.error = true;
            }
          });
      });
    }
  }
};
</script>
<style></style>
